export const BACKEND_PATH =
  //  todo
  // `http://127.0.0.1:3006`;
  `https://anomgaming.online`;

export const COINAPI_WS_URL = "wss://ws.coinapi.io/v1/";
export const COINAPI_KEY = "3419ED45-4A22-4B57-873A-46717EDA8CC9";
// export const COINAPI_KEY = "f28ff064-ef4b-40a5-b16e-24fd412936e7";
// export const COINAPI_KEY = "5a77bd04-7df9-453a-a291-fe49b6dbb9fb";

export const BINANCE_WS_URL = "wss://fstream.binance.com/ws/ethusdt@trade";

export const BOT_URL = "https://t.me/anom_invaders_bot/anom";
